export default class MenuComponent {
    constructor() {
        MenuComponent.title()
    }

    static title() {
        const title = '.fullscreen-menu__item' // Can't use a data-trigger as it's defined conditionally in the template
        const submenuTarget = 'wpiris-menu-submenu'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        let currentSubmenu
        let previousSubmenu

        //Open/Close submenu
        $(title).on('click', function () {
            const submenuId = $(this).data('submenu-id')
            if (submenuId) {
                triggerActiveEntry(this)
            }
        })

        //Close submenu
        $(prev).on('click', () => {
            $(`[data-element=${submenuTarget}]`).removeClass('active')
            $(title).removeClass('active')
            $('body').removeClass('submenu-open')
        })

        function triggerActiveEntry(submenuTitleElement) {
            $(`[data-element='${submenuTarget}']`).removeClass('active')
            $(title).removeClass('active')
            $(submenuTitleElement).addClass('active')

            // Checking previous submenu opening
            if ($(submenuTitleElement).data('submenu-id')) {
                previousSubmenu = $(submenuTitleElement).data('submenu-id')
            }
            currentSubmenu = $(submenuTitleElement).data('submenu-id')

            // Checking whether the current title has a submenu
            if ($(submenuTitleElement).is(`[data-trigger='${submenuTarget}']`)) {
                $('body').addClass('submenu-open')
                $(`[data-element='${submenuTarget}']`)
                    .filter(`[data-submenu-id='${currentSubmenu}']`)
                    .addClass('active')
            } else {
                $('body').removeClass('submenu-open')
            }
        }
    }
}
