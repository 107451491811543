export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 100 //Trigger offset (~header height)
            const $navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > $navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                $navbar.removeClass('navbar--ontop')
                $navbar.addClass('navbar--onscroll')
                if (full === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                $navbar.removeClass('navbar--onscroll')
                $navbar.addClass('navbar--ontop')
                if (full === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const $body = $('body')

        //Close menu when opening eco-bar
        $('[data-toggle="#eco-bar"]').on('click', () => {
            $body.removeClass('menu-open').removeClass('submenu-open')

            if ($body.hasClass('has-banner')) {
                NavbarComponent.changeLogoColor('banner')
            }
        })

        //Open/Close menu
        $(trigger).on('click', () => {
            $body.toggleClass('menu-open').removeClass('submenu-open')

            $('.fullscreen-menu__item').removeClass('active')
            $("[data-element='wpiris-menu-submenu']").parent().removeClass('active')
            $('.fullscreen-menu__column--3 img').removeClass('new')

            $('#eco-bar').addClass('eco-bar--hidden')

            // If menu opened
            // 	-> Mobile : banner
            // 	-> Desktop : classic
            // Else (so, if menu closed)
            // 	-> body.has-banner + nav.navbar--ontop : banner
            // 	-> else : classic
            if ($body.hasClass('menu-open')) {
                if (window.matchMedia('(max-width: 1000px)').matches) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else {
                if ($body.hasClass('has-banner') && $('.navbar--ontop').length > 0) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    NavbarComponent.changeLogoColor('classic')
                }
            }
        })
    }
}
